<template>
  <div>
    <div style="width: 100%">
      <img style="width: 100%" src="./img/顶部背景.png" alt="" />
    </div>

    <div v-if="isshow" class="bottomCss">
      <!-- 第一组产品 -->
      <div id="chuneng" class="chanpingCss">
        <div
          class="xinwen"
          v-for="(item, index) in this.xinwenData"
          :key="index"
        >
          <div class="imgCss">
            <img
              style="
                width: 420px;
                /* height: 240px;
                border-radius: 12px 12px 12px 12px; */
                /* object-fit: cover; */
              "
              :src="item.content.dataList[0].url"
              alt=""
            />
          </div>
          <div class="rightDiv">
            <div class="nameCss">{{ item.content.dataList[0].name }}</div>
            <div class="jieshao">{{ item.content.dataList[0].jieshao }}</div>
            <div
              style="
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
              "
            >
              <div
                style="
                  font-size: 18px;
                  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                  font-weight: 400;
                  color: rgba(0, 0, 0, 0.4);
                "
              >
                {{ item.content.dataList[0].time }}
              </div>
              <button class="ckxz" @click="go(item)">查看详情</button>
            </div>
          </div>
        </div>

        <el-pagination
          style="text-align: center; margin-top: 14px"
          background
          @current-change="handleCurrentChange"
          :current-page="queryParams.pageNum"
          :page-size="queryParams.pageSize"
          layout="prev, pager, next"
          :total="queryParams.total"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 内容页 -->
    <div>
      <div style="background: #f2f5f8; padding-top: 64px">
        <div
          style="
            width: 1314px;
            margin-left: auto;
            margin-right: auto;
            background: #ffffff;
          "
          class="ql-snow"
          v-if="!isshow"
        >
          <div
            style="
              padding-top: 54px;
              font-size: 38px;
              font-family: Microsoft YaHei-Bold, Microsoft YaHei;
              font-weight: bold;
              color: #000000;
              text-align: center;
            "
          >
            {{ this.editorText.content.dataList[0].name }}
          </div>
          <div
            style="
              font-size: 18px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.4);
              padding-top: 16px;
              text-align: center;
            "
          >
            {{ this.editorText.content.dataList[0].time }}
          </div>
          <div
            class="ql-editor"
            id="editorText"
            v-html="this.editorText.content.dataList[0].editorText"
          ></div>
        </div>
      </div>

      <div style="position: fixed; right: 10px; bottom: 144px">
        <div v-if="!isshow" @click="fanhui" class="fhsj">
          <div class="fanhui">
            <img src="@/assets/返回上级蓝.png" alt="" />
          </div>
          <div>返回上级</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { uploadFile, getContents, getNewsContent } from "@/api/index.js";
export default {
  data() {
    return {
      isshow: true,
      editorText: "",
      xinwenData: [
        {
          name: "首次“新能源产业及应用”专题援外培训顺利完成",
          jieshao:
            "由中华人民共和国商务部主办，中国信科集团承办的发展中国家援外培训班于今年6月顺利启动。近日，“物联网技术及应用研修班”、“移动互联网发展研修班”和“下一代通信网络建设研修班”陆续在汉举行隆重的开班典礼。本次研修班，学员分别来自阿塞拜疆、老挝、蒙古、伊拉克、乌兹别克斯坦、亚美尼亚、尼泊尔、埃及、突尼斯、加纳、尼日利亚、苏里南、摩洛哥、莱索托、牙买加、古巴、萨摩亚共18个发展中国家的内政部、通信部、科技部、电信局、国家电视台、高校和相关企业，含4位司局级、37位处级和多位高级管理和技术人员。",
          url: require("./img/援外.jpg"),
          time: "2023/06/20",
          router: "/index230620",
        },
        {
          name: "烽火富华荣获“2023中国充换电行业十大竞争力品牌”",
          jieshao:
            "6月7日，由中国充电桩网、充换电百人会主办的“2023上海国际充电桩及换电技术设备展览会（简称CPSE）”在上海汽车会展中心盛大开展。超2.5万平米展馆、超500家展商、超2万人以上专业观众共同组成了声势浩大的展会阵容。在展会同期举办的“2023中国充换电行业十大品牌评选活动”上，烽火富华荣获“2023中国充换电行业十大竞争力品牌”。",
          url: require("./img/喜报.jpg"),
          time: "2023/06/09",
          router: "/index230609",
        },
      ],
      queryParams: {
        pageNum: 1,
        pageSize: 5,
        total: 0,
      },
    };
  },
  methods: {
    fanhui() {
      this.isshow = !this.isshow;
    },
    handleCurrentChange(val) {
      this.queryParams.pageNum = val;
      this.getList();
    },
    async getList() {
      let params = {
        menuId: "2004",
        id: "",
        pageNum: this.queryParams.pageNum,
        pageSize: this.queryParams.pageSize,
      };
      const res = await getNewsContent(params);
      for (let index = 0; index < res.rows.length; index++) {
        // const zj = JSON.parse(res[0].content);
        res.rows[index].content = JSON.parse(res.rows[index].content);
      }
      // const zj = JSON.parse(res[0].content);
      console.log(res, "ress");
      this.xinwenData = res.rows;
      this.queryParams.total = parseInt(res.total);
    },
    //路由跳转事件
    go(index) {
      console.log(index);
      // this.$router.push(index.router);
      this.isshow = !this.isshow;

      // var c = document.getElementById("editorText");
      // c.innerHTML = index.content.dataList[0].editorText;
      this.editorText = index;
      // this.editorText = index.content.dataList[0].editorText;
    },
    change(x) {
      this.current = x;
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style scoped>
.fanhui {
}
::v-deep.el-backtop {
  width: 82px;
  height: 82px;
}
.fhsj {
  cursor: pointer;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  /* text-align: center; */
  width: 82px;
  height: 82px;
  background: #ffffff;
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.fhsj:hover {
  cursor: pointer;
  background: #0940fd;
  color: rgba(255, 255, 255, 0.6);
  .fanhui img {
    content: url("../../assets/返回上级.png");
  }
}
.XWXQCss {
  width: 1314px;
  background: #ffffff;
}
.bottomCss {
  width: 100%;
  height: 100%;
  padding-bottom: 88px;
  background: #f2f5f8;
}
.chanpingCss {
  padding-top: 72px;
  display: flex;
  /* text-align: center; */
  flex-direction: column;
  align-items: center;
}
.xinwen {
  display: flex;
  width: 1314px;
  height: 360px;
  background: #ffffff;
  margin-bottom: 16px;
  /* margin-top: 72px; */
}
.xinwen:hover {
  display: flex;
  width: 1314px;
  height: 360px;
  background: #ffffff;
  margin-bottom: 16px;
  box-shadow: 0px 0px 30px 1px rgba(9, 64, 253, 0.2);
}
.xinwen:hover::before {
  content: "";
  border-left: 8px solid #0940fd;
  height: 240px;
  margin-top: 54px;
}
.rightDiv {
  /* margin-left: 64px; */
}
.nameCss {
  font-size: 26px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  margin-top: 54px;
  color: rgba(0, 0, 0, 0.9);
  height: 26px;
  width: 28em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.jieshao {
  margin-top: 24px;
  width: 732px;
  font-size: 17px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 38px;

  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.imgCss {
  margin: 60px 42px 60px 60px;
  width: 420px;
  height: 240px;
  overflow: hidden;
  border-radius: 12px;
  display: flex;
  align-items: center;
}
.ckxz {
  margin-top: 28px;
  width: 138px;
  height: 58px;
  background: #e5ebff;
  border-radius: 12px 12px 12px 12px;
  border: none;
  font-size: 20px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #0940fd;
}
.ckxz:hover {
  background: #0940fd;
  color: #ffffff;
}
</style>